<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <h5>Faturas aprovadas para auditoria</h5>
        <DataTable ref="rf" exportFilename="relatorio[Faturas Aprovadas]" csvSeparator=";" :value="dadosAprovados"
          :stickyHeader="true" dataKey="id_importacao_aprovada" :paginator="true" :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column v-for="col of cabecalho" :key="col.field" :field="col.field" :header="col.header"
            style="white-space: nowrap">
            <template #body="slotProps">
              <span v-if="col.field === 'acoes'">
                <span v-if="slotProps.data.status_envio === 'sent'">
                  <Button icon="pi pi-upload" class="p-button-rounded p-button-info" disabled
                    tooltip="Envio já realizado" />
                </span>
                <span v-else>
                  <Button icon="pi pi-upload" class="p-button-rounded p-button-info"
                    @click="abrirModal(slotProps.data)" />
                </span>
              </span>
              <span v-else-if="col.field === 'status_envio'">
                {{ traduzirStatusEnvio(slotProps.data.status_envio) }}
              </span>
              <span v-else-if="col.field === 'status_auditoria'">
                {{ traduzirStatusAuditoria(slotProps.data.status_auditoria) }}
              </span>
              <span v-else>
                {{ slotProps.data[col.field] }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div class="col-4">
      <Dialog v-model:visible="upload" :style="{ width: '600px' }" :modal="true" header="Importar PDF">
        <div class="flex align-items-center justify-content-center">
          <div class="field">
            <FileUpload chooseLabel="Selecionar arquivo PDF" ref="arquivo" mode="basic" name="arquivo"
              accept="application/pdf" @change="onChangeFileUpload" />
          </div>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="fecharModal" />
          <Button label="Importar" icon="pi pi-check" class="p-button-success p-button-sm" @click="enviarFatura" />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dadosAprovados: [],
      filters: {
        global: { value: null }
      },
      cabecalho: [
        { header: "ID Importação", field: "planilha_id" },
        { header: "ID Fatura", field: "fatura_id" },
        { header: "Valor", field: "valor" },
        { header: "Status Envio", field: "status_envio" },
        { header: "Status Auditoria", field: "status_auditoria" },
        { header: "Ações", field: "acoes" }
      ],
      upload: false,
      arquivo: null,
      selectedImportacao: null
    };
  },
  mounted() {
    this.listaDadosAprovados();
  },
  methods: {
    abrirModal(registro) {
      this.selectedImportacao = registro;
      this.upload = true;
    },
    fecharModal() {
      this.upload = false;
      this.listaDadosAprovados();
    },
    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0];
    },
    enviarFatura() {
      if (!this.arquivo) {
        this.$toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Selecione um arquivo antes de enviar.",
          life: 3000
        });
        return;
      }
      if (!this.selectedImportacao) {
        this.$toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Nenhum registro foi selecionado.",
          life: 3000
        });
        return;
      }

      const formData = new FormData();
      formData.append("arquivo", this.arquivo);
      formData.append("id_importacao_aprovada", this.selectedImportacao.id_importacao_aprovada);

      this.axios
        .post("/fatura/enviar-fatura", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Arquivo enviado com sucesso",
            life: 3000
          });
          this.upload = false;
          this.listaDadosAprovados();
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao enviar o arquivo",
            life: 3000
          });
        });
    },
    listaDadosAprovados() {
      this.axios
        .get("/relatorios/listar-dados-aprovadas")
        .then((response) => {
          this.dadosAprovados = response.data.dados;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Falha ao carregar os dados aprovados!",
            life: 3000
          });
        });
    },
    exportCSV2() {
      this.$refs.rf.exportCSV();
    },
    traduzirStatusEnvio(status) {
      const map = {
        pending: "Pendente",
        sent: "Enviado"
      };
      return map[status] || status;
    },
    traduzirStatusAuditoria(status) {
      const map = {
        pending: "Pendente",
        approved: "Aprovado",
        rejected: "Rejeitado"
      };
      return map[status] || status;
    }
  }
};
</script>

<style scoped>
.tabela {
  margin: 20px 0;
}

.tabela th {
  white-space: nowrap;
  background: #eee;
  padding: 5px;
}
</style>
